import styled from 'styled-components';

// colors
import {red} from '@matthahn/sally-ui/lib/libs/colors';

const Link = styled.a`
  color: inherit;
  cursor: pointer;
  user-select: none;
  display: flex;
  gap: 2px;

  &:hover {
    color: ${red};
  }
`;

export default Link;
