// local components
import Link from './components/Link';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const OpenInGeorge = ({children, url}) => (
  <Link href={url} target="_blank">
    {children}
    <i className="mdi mdi-open-in-new" />
  </Link>
);

OpenInGeorge.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
};

export default OpenInGeorge;
