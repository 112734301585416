// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// george constants
import GEORGE_URL from '../../../george/constants/url.constants.george';

// kuiper constants
import KUIPER_URL from '../../../kuiper/constants/url.constants.kuiper';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const DriverProfileButton = ({driver, noProfileDom = null}) => {
  return !!driver?.george_id ? (
    <a
      href={`${GEORGE_URL()}/drivers/${driver.george_id}`}
      target="_blank"
      rel="noreferrer"
    >
      <Button theme="red" size="small">
        George
      </Button>
    </a>
  ) : !!driver?.kuiper_id ? (
    <a
      href={`${KUIPER_URL()}/drivers/${driver.kuiper_id}`}
      target="_blank"
      rel="noreferrer"
    >
      <Button theme="orange" size="small">
        Kuiper
      </Button>
    </a>
  ) : (
    noProfileDom
  );
};

DriverProfileButton.propTypes = {
  driver: PropTypes.object,
};

export default DriverProfileButton;
