// george components
import OpenInGeorge from '../OpenInGeorge/OpenInGeorge';

// george routes
import georgeDriverRoute from '../../routes/driver.route.george';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const OpenDriverInGeorge = ({children, driverId}) => (
  <OpenInGeorge url={georgeDriverRoute(driverId)}>{children}</OpenInGeorge>
);

OpenDriverInGeorge.propTypes = {
  children: PropTypes.node,
  driverId: PropTypes.number.isRequired,
};

export default OpenDriverInGeorge;
