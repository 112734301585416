// george components
import OpenInGeorge from '../OpenInGeorge/OpenInGeorge';

// george routes
import georgeVehicleRoute from '../../routes/vehicle.route.george';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const OpenVehicleInGeorge = ({children, vehicleId}) => (
  <OpenInGeorge url={georgeVehicleRoute(vehicleId)}>{children}</OpenInGeorge>
);

OpenVehicleInGeorge.propTypes = {
  children: PropTypes.node,
  vehicleId: PropTypes.number.isRequired,
};

export default OpenVehicleInGeorge;
